import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import {getContextRoot, getLanguageValue, navigateToPath, resetRegisterIndividualStagesData} from "../common/functions";
import CONSTANTS from '../common/constants';
import MenuDropDown from './MenuDropDown';
import { ACTIONS } from "../redux/actions";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Grid from '@material-ui/core/Grid';

function WhoCanGetAndHowToStart(props) {

    const dispatch = useDispatch();

    return <WhoCanGetAndHowToStartStyle className="who-can-get-visa-and-how-to-start-component">
        <div className="container">
            <h2>{getLanguageValue('content-body.who-can-get-a-visa')}</h2>
            <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.who-can-get-a-visa.allowed-citizen')}
            <br/>
            <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.who-can-get-a-visa.travel-time-x-days-or-less')}
            <br/>
            <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.who-can-get-a-visa.travel-purpose-for-business-or-pleasure')}
            <br/>
            <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.who-can-get-a-visa.travel-for-new-visa-for-one-or-group')}
            <br/>
            <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.who-can-get-a-visa.valid-for-60-days')}
            <br/>
            <div className = "gfg">
            <img src="/images/etac.png"/>

         </div>
            <br/>
            <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-countries')}

            <h2>{getLanguageValue('content-body.required-to-start-a-visa')}</h2>
            <Grid container>
                <Grid item md={6}>
                    <AcUnitIcon className="icon-component" />{getLanguageValue('content-body.required-to-start-a-visa.valid-passport')}
                    <br/>
                    <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-to-start-a-visa.valid-email')}
                    <br/>
                    <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-to-start-a-visa.valid-address-and-phone')}
                    <br/>
                    <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-to-start-a-visa.valid-emergency-contact')}
                    <br/>
                    <AcUnitIcon className="icon-component"/><span className="payment-text">{getLanguageValue('content-body.required-to-start-a-visa.payment')}</span>
                    <br/>
                    <div className="payment-side-padding-text">{getLanguageValue('content-body.required-to-start-a-visa.payment-valid-options')}</div>
                    <img alt={''} className={'payment-methods-image'} src={getContextRoot() + 'images/payment_methods.png'} />
                    <br/>
                    <AcUnitIcon className="icon-component"/><span className="payment-text">{getLanguageValue('content-body.required-to-start-a-visa.prices')}</span>
                    <div className="payment-side-padding-text" dangerouslySetInnerHTML={{ __html: getLanguageValue('content-body.required-to-start-a-visa.payment-cost-text') }} />
                </Grid>
                <Grid item md={6}>
                    <div className="more-info-text">{getLanguageValue('content-body.required-to-start-a-visa.more-information-required')}</div>
                    <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-to-start-a-visa.names-and-nicknames')}
                    <br/>
                    <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-to-start-a-visa.id-number')}
                    <br/>
                    <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-to-start-a-visa.employer-and-address')}
                    <br/>
                    <AcUnitIcon className="icon-component"/>{getLanguageValue('content-body.required-to-start-a-visa.valid-contact-address-in-country')}
                </Grid>
            </Grid>
            <div className="gender-notification">{getLanguageValue('content-body.required-to-start-a-visa.for-men-and-women')}</div>
            <div className="start-a-visa-button-container">
                <Button onClick={startVisa} className="start-a-visa-button">{getLanguageValue('content-body.required-to-start-a-visa.start')}</Button>
            </div>
        </div>
    </WhoCanGetAndHowToStartStyle>;

    function startVisa() {
        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'globalControls.showNoNewVisasPopup',
                    value: true
                }]
            });
            return;
        }
        resetRegisterIndividualStagesData(dispatch, props.state);
        dispatch({
            type: ACTIONS.GENERIC_SET_VALUE,
            payload: [
                {
                    path: 'globalControls.openWarningForIndividual',
                    value: true
                },
                {
                    path: 'warningForIndividualConfig',
                    value: {
                        title: getLanguageValue('register-application-warning-before-starting-individual-visa-title'),
                        content: getLanguageValue('register-application-warning-before-starting-individual-visa-content')
                    }
                }
            ]
        });
    }
}

const WhoCanGetAndHowToStartStyle = styled.div`
    background-image: url(${getContextRoot() + 'images/who-can-get-a-visa-bg.jpg'});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 715px;
    padding: 10px;

    .gfg {
                width:auto;
                text-align:right;
                padding:20px;
            }
            img {
                max-width:100%;
                        height:auto;
            }
    .icon-component {
      position: relative;
      top: 5px;
      html.rtl & {
        padding-left: 5px;
      }
      html.ltr & {
        padding-right: 5px;
      }
    }
    .container {
      font-size: 22px;
      background-color: rgba(187, 193, 199, 0.95);
      margin: 0 auto;
      line-height: 37px;
      width: 80%;
      border-radius: 15px;
      padding: 30px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 16px;
      }
    }
    .payment-text {
      font-weight: bold;
    }
    .more-info-text {
      font-weight: bold;
    }
    .payment-side-padding-text {
      html.rtl & {
        margin-right: 25px;
      }
      html.ltr & {
        margin-left: 25px;
      }
    }
    .payment-methods-image {
      max-height: 50px;
      html.rtl & {
        padding-right: 25px;
      }
      html.ltr & {
        padding-left: 25px;
      }
    }
    .gender-notification {
      font-size: 15px;
      position: relative;
      top: 45px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        top: 5px;
      }
    }
    .start-a-visa-button-container {
      text-align: center;
    }
    .start-a-visa-button {
      font-size: 22px;
      color: white;
      background-color: #1c3764;
    }
`;

const WhoCanGetAndHowToStartComponent = connect(
    (state) => ({
        state: state,
        languageKey: state.languageKey, // make everything re-render
    }),
    {})(WhoCanGetAndHowToStart);

export default WhoCanGetAndHowToStartComponent;
