/* eslint-disable eqeqeq, default-case */
import React, { useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import Button from '@material-ui/core/Button';
import {
    changeFieldsWithPrefixAndCamelCase,
    getApiRoot,
    getLanguageValue,
    googleRecaptcha,
    executeSingleRequest,
    isInactiveField, convertDateFieldsIntoString
} from "../../common/functions";
import ModalComponent from "../Modal";
import CONSTANTS from '../../common/constants';
import lodashGet from 'lodash/get';
import lodashCloneDeep from 'lodash/cloneDeep';
import GenericFieldComponent from "../GenericFieldComponent";
import Loader from "../Loader";
import {ACTIONS} from "../../redux/actions";
import {CSS_COLORS} from "../../common/cssColors";
import {reportCustomerEmailVerification} from "../../common/googleReporter";
import moment from 'moment';

function RegisterStage2SubProcessComponent(props) {
    const {
        replacements,
        uploadedStageFiles,
        actions,
        minorGoingWithParent
    } = props;

    const [ isOpen, setIsOpen ] = useState(true);

    const [ loaders, setLoaders ] = useState({ }); // generic loaders states (verifyCode, confirmCode, resendCode)
    const [ subProcessStage, setSubProcessStage ] = useState(1); // active stage
    const [ stage2Code, setStage2Code ] = useState('');
    const [ stage2CodeError, setStage2CodeError ] = useState(false);
    const [ stage2BottomError, setStage2BottomError ] = useState(false);
    const [ isSentCodeAgain, setIsSentCodeAgain ] = useState(false);

    const dispatch = useDispatch();

    // setup by stage
    let title = '';
    let content = '';

    switch (subProcessStage) {
        case 1:
            title = replaceValues(getLanguageValue('register-individual.stage2-subprocess-verify-email'));
            content = <ModalContentStyle className={"modal-content-container"} >
                    <div className={"message-container"} dangerouslySetInnerHTML={{ __html: replaceValues(getLanguageValue('register-individual.stage2-subprocess-verify-email-explanation')) }} />
                    <div className={"actions-container"}>
                        <Button disabled={loaders.verifyCode} className={"confirm-button common-style-primary-button"} onClick={stage1Continue}>{getLanguageValue('register-individual.stage2-subprocess-confirm-button')} {loaders.verifyCode && <Loader className={"loader-component"} />}</Button>
                        <Button disabled={loaders.verifyCode} className={"change-email-button common-style-default-button"} onClick={stage1ChangeEmail}>{getLanguageValue('register-individual.stage2-subprocess-change-email-button')}</Button>
                    </div>
                    {stage2CodeError ? <div className="error-message-container">{stage2CodeError}</div> : null}
                    <GlobalStyle />
                </ModalContentStyle>;
            break;
        case 2:
            title = getLanguageValue('register-individual.stage2-subprocess-enter-code');
            content = <ModalContentStyle className={"modal-content-container"} >
                <div className={"message-container"} dangerouslySetInnerHTML={{ __html: replaceValues(isSentCodeAgain ? getLanguageValue('register-individual.stage2-subprocess-enter-resend-code-explanation') : getLanguageValue('register-individual.stage2-subprocess-enter-code-explanation')) }} />
                <div className={"actions-container"}>
                    <div className="code-input-container">
                        <GenericFieldComponent
                            fieldProps={{
                                autoFocus: true,
                                className: 'field-component',
                                disabled: isLoaderActive(),
                                error: stage2CodeError != false,
                                value: stage2Code,
                                inputProps: {
                                    maxLength: 4,
                                    name: 'stage2Code'
                                },
                                helperText: stage2CodeError || '',
                                onChange: (e) => { stage2Change4DigitCode(e); },
                                onKeyDown: (e) => { if (e.keyCode === 13) stage2Confirm(); },
                                label: getLanguageValue('register-individual.stage2-subprocess-code-field-input')
                            }}
                        />
                    </div>
                    <div className={"loader-container"}>
                        {isLoaderActive() && <Loader className={"loader-component"} />}
                        {loaders.confirmCode ? <div className="uploading-notification-container">{getLanguageValue('register-individual.stage2-subprocess-uploading-notification')}<br/><br/></div> : null}
                    </div>
                    <Button disabled={isLoaderActive() || !stage2Code.length} className={"confirm-code-button common-style-primary-button"} onClick={stage2Confirm}>{getLanguageValue('register-individual.stage2-subprocess-confirm-code-button')} </Button>
                    <Button disabled={isLoaderActive()} className={"resend-code-button common-style-default-button"} onClick={stage2ResendCode}>{getLanguageValue('register-individual.stage2-subprocess-resend-code-button')}</Button>
                    <Button disabled={isLoaderActive()} className={"cancel-button common-style-default-button"} onClick={stage2Cancel}>{getLanguageValue('register-individual.stage2-subprocess-cancel-button')}</Button>
                </div>
                {stage2BottomError ? <div className={"error-container"}>{stage2BottomError}</div> : null}
                <GlobalStyle />
            </ModalContentStyle>;
    }

    return <ModalComponent
        isOpen={isOpen}
        modalClassName={'register-individual-stage2-subprocess-modal'}
        disableBackdropClick={true}
        showTitleCloseButton={true}
        onClose={(src) => { if (isLoaderActive()) { return; } setIsOpen(false); actions.onClose('abort'); }}
        title={title}
        content={content}
    />;

    function replaceValues(originalValue) {
        if (replacements && originalValue) {
            for (let replacementItem of replacements) {
                originalValue = originalValue.replace(replacementItem.value, replacementItem.newValue);
            }
        }
        return originalValue;
    }

    // call the api and move to the next step
    async function stage1Continue() {
        let duplicateData = lodashCloneDeep(props.stage2Data);
        // remove error flags from internal objects
        for (let item of duplicateData.previousCitizenOfCountries) {
            delete item.errors;
        }
        for (let item of duplicateData.currentlyCitizenOfCountries) {
            delete item.errors;
        }
        // cleanup if there are no values
        if (duplicateData.previousCitizenOfCountries.length == 1 && duplicateData.previousCitizenOfCountries[0].startDate == null) {
            duplicateData.previousCitizenOfCountries = [];
        }
        if (duplicateData.currentlyCitizenOfCountries.length == 1 && duplicateData.currentlyCitizenOfCountries[0].howCitizenshipReceived == 'select') {
            duplicateData.currentlyCitizenOfCountries = [];
        }
        // fix date objects into string in format YYYY-MM-DD
        convertDateFieldsIntoString(duplicateData, 'YYYY-MM-DD');
        // go over inner fields of previousCitizenOfCountries
        if (duplicateData.previousCitizenOfCountries.length) {
            for (let loop = 0 ; loop < duplicateData.previousCitizenOfCountries.length ; ++loop) {
                let item = duplicateData.previousCitizenOfCountries[loop];
                convertDateFieldsIntoString(item, 'YYYY-MM-DD');
            }
        }
        duplicateData.visaExpeditedFlag = (duplicateData.visaExpeditedFlag == true ? 1 : 0);
      
        setStage2CodeError(false);
        delete duplicateData.emailConfirm;
        let isError = false;
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                ...changeFieldsWithPrefixAndCamelCase(duplicateData, 'stage2'),
                recaptchaToken
            })
        };
        if (loaders.verifyCode) {
            return;
        }
        try {
            setLoaders({ ...loaders, verifyCode: true });
            const url = getApiRoot() + 'init-process/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let result = await (await fetch(url, options)).json();
            if (!lodashGet(result, 'data.key')) {
                // error
                isError = true;
                setStage2CodeError(getLanguageValue('register-network-error'));
            }
            else {
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [
                        {
                            path: 'apiData.individual.key',
                            value: lodashGet(result, 'data.key')
                        },
                        {
                            path: 'registerIndividualStagesData',
                            value: { ...props.registerIndividualStagesData, createDate: lodashGet(result, 'data.createDate'), status: 'unpaid' }
                        }
                    ]
                });
            }
        }
        catch (err) {
            // nothing to do
            isError = true;
            setStage2CodeError(getLanguageValue('register-network-error'));
        }
        setLoaders({ ...loaders, verifyCode: false });
        if (!isError) {
            setSubProcessStage(2);
        }
    }

    function stage1ChangeEmail() {
        actions.onClose('abort');
    }

    function stage2Change4DigitCode(e) {
        const value = e.target.value;
        setStage2Code(value);
        if (!value.length || /^\d{4}$/.test(value)) {
            setStage2CodeError(false);
        }
        else {
            setStage2CodeError(getLanguageValue('register-individual.stage2-subprocess-code-field-error-message'));
        }
    }

    async function stage2Confirm() {
        setLoaders({ ...loaders, confirmCode: true });
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                key: props.apiIndividualKey,
                code: stage2Code,
                recaptchaToken
            })
        };
        let url, result;
        try {
            url = getApiRoot() + 'confirm-code/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            result = await (await fetch(url, options)).json();
            if (!lodashGet(result, 'data.success') && !lodashGet(result, 'data.alreadyVerified')) {
                let errorMessageValue;
                if (result.err == 'code expired') {
                    errorMessageValue = getLanguageValue('register-individual.stage2-subprocess-expired-code-error-message');
                }
                else {
                    errorMessageValue = getLanguageValue('register-individual.stage2-subprocess-invalid-code-error-message');
                }
                setStage2CodeError(errorMessageValue);
            }
            else {
                // code ok - upload file (if have any), attach to group (if should) and continue
                reportCustomerEmailVerification();
                if (lodashGet(uploadedStageFiles, 'passport.base64')) {
                    try {
                        let fileTypes = [ 'passport', 'passportFace', 'personalIdWithAppendix', 'healthGreenCardOrVaccination' ];
                        if (shouldAttachBirthCertificateAndParentPassport()) {
                            fileTypes = fileTypes.concat(CONSTANTS.REQUIRED_IMAGES_FOR_MINOR);
                        }
                        if (shouldAttachPassportBackside()) {
                            fileTypes = fileTypes.concat('passportBackside');
                        }
                        if (areBusinessFieldsRelevant()) {
                            fileTypes = fileTypes.concat('businessCard');
                        }
                        let promises = [];
                        for (let fileType of fileTypes) {
                            if (lodashGet(uploadedStageFiles, fileType + '.base64')) {
                                let boundPromise = executeSingleRequest.bind({
                                    url: getApiRoot() + 'upload-files/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp),
                                    requestBody: {
                                        key: props.apiIndividualKey,
                                        reset: true,
                                        filesArray: [
                                            {
                                                fileType,
                                                filename: lodashGet(uploadedStageFiles, fileType + '.filename'),
                                                fileContent: lodashGet(uploadedStageFiles, fileType + '.base64')
                                            }
                                        ]
                                    }
                                });
                                promises.push(boundPromise());
                            }
                        }
                        let results = await Promise.all(promises);
                        for (let result of results) {
                            if (!lodashGet(result, 'data.success')) {
                                setStage2CodeError(getLanguageValue('register-file-upload-error'));
                                setLoaders({ ...loaders, confirmCode: false });
                                return;
                            }
                        }
                    }
                    catch (err) {
                        setStage2CodeError(getLanguageValue('register-file-upload-error'));
                        setLoaders({ ...loaders, confirmCode: false });
                        return;
                    }
                }
                // now - final confirmation
                try {
                    recaptchaToken = await googleRecaptcha();
                    options = {
                        ...CONSTANTS.POST_DEFAULT_OPTIONS,
                        body: JSON.stringify({
                            key: props.apiIndividualKey,
                            recaptchaToken
                        })
                    };
                    url = getApiRoot() + 'validate-individual-form/?languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                    result = await (await fetch(url, options)).json();
                    if (!lodashGet(result, 'data.success')) {
                        setStage2CodeError(getLanguageValue('register-network-error'));
                        setLoaders({ ...loaders, confirmCode: false });
                        return;
                    }
                }
                catch (err) {
                    setStage2CodeError(getLanguageValue('register-network-error'));
                    setLoaders({ ...loaders, confirmCode: false });
                    return;
                }

                let attachIndividualReduxData = null;
                if (props.isIndividualLinkedToGroup) {
                    recaptchaToken = await googleRecaptcha();
                    url = getApiRoot() + 'attach-individual-application-to-group/?languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
                    options = {
                        ...CONSTANTS.POST_DEFAULT_OPTIONS,
                        body: JSON.stringify({
                            recaptchaToken,
                            individualKey: props.apiIndividualKey,
                            groupKey: props.groupKey,
                            passportNumber: lodashGet(props.stage2Data, 'passportNumber'),
                            birthDate: moment(lodashGet(props.stage2Data, 'birthDate')).format('YYYY-MM-DD')
                        })
                    };
                    try {
                        // hook the individual application to the group application
                        result = await (await fetch(url, options)).json();
                        if (!lodashGet(result, 'data.success')) {
                            setStage2CodeError(getLanguageValue('register-individual-stage2-attach-to-group-error'));
                            setLoaders({ ...loaders, confirmCode: false });
                            return;
                        }
                        // success - add in redux+
                        if (Array.isArray(props.registerGroupStage2Data?.linkedIndividualApplications)) {
                            attachIndividualReduxData = [
                                ...props.registerGroupStage2Data?.linkedIndividualApplications,
                                {
                                    name: lodashGet(props, 'stage2Data.firstName') + ' ' + lodashGet(props, 'stage2Data.lastName'),
                                    birthDate: lodashGet(props, 'stage2Data.birthDate'),
                                    key: lodashGet(props, 'individualKey'),
                                    passportNumber: lodashGet(props, 'stage2Data.passportNumber'),
                                    status: 'unpaid',
                                    visaType: lodashGet(props, 'stage2Data.visaType'),
                                    visaExpedited: lodashGet(props, 'stage2Data.visaExpeditedFlag'),
                                    visaDeliveryType: lodashGet(props, 'stage2Data.visaDeliveryType'),
                                    visaDeliveryDestination: lodashGet(props, 'stage2Data.visaDeliveryDestination'),
                                    rejectReasons: {}
                                }
                            ];
                        }
                    }
                    catch (err) {
                        // nothing to do
                        setStage2CodeError(getLanguageValue('register-individual-stage2-attach-to-group-error'));
                        setLoaders({ ...loaders, confirmCode: false });
                        return;
                    }
                }
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [
                        {
                            path: 'registerIndividualStagesData.stage2UploadedPassport',
                            value: true
                        },
                        {
                            path: 'registerIndividualStagesData.stage2UploadedPassportFace',
                            value: true
                        },
                        {
                            path: 'registerIndividualStagesData.stage2UploadedPersonalIdWithAppendix',
                            value: true
                        },
                        {
                            path: 'registerIndividualStagesData.stage2UploadedHealthGreenCardOrVaccination',
                            value: true
                        },
                        {
                            path: 'registerIndividualStagesData.key',
                            value: props.apiIndividualKey
                        },
                        {
                            path: 'registerIndividualStagesData.uploadedFiles',
                            value: [
                                {
                                    fileType: 'passport',
                                    filename: lodashGet(uploadedStageFiles, 'passport.filename'),
                                    fileContent: lodashGet(uploadedStageFiles, 'passport.base64')
                                },
                                ...(!isInactiveField('stage2', 'passportFace') ? [{
                                    fileType: 'passportFace',
                                    filename: lodashGet(uploadedStageFiles, 'passportFace.filename'),
                                    fileContent: lodashGet(uploadedStageFiles, 'passportFace.base64')
                                }]: []),
                                ...(!isInactiveField('stage2', 'personalIdWithAppendix') ? [{
                                    fileType: 'personalIdWithAppendix',
                                    filename: lodashGet(uploadedStageFiles, 'personalIdWithAppendix.filename'),
                                    fileContent: lodashGet(uploadedStageFiles, 'personalIdWithAppendix.base64')
                                }]: []),
                                ...(!isInactiveField('stage2', 'healthGreenCardOrVaccination') ? [{
                                    fileType: 'healthGreenCardOrVaccination',
                                    filename: lodashGet(uploadedStageFiles, 'healthGreenCardOrVaccination.filename'),
                                    fileContent: lodashGet(uploadedStageFiles, 'healthGreenCardOrVaccination.base64')
                                }]: []),
                                ...(shouldAttachPassportBackside() ? [ {
                                    fileType: 'passportBackside',
                                    filename: lodashGet(uploadedStageFiles, 'passportBackside.filename'),
                                    fileContent: lodashGet(uploadedStageFiles, 'passportBackside.base64')
                                    } ] : []),
                                ...(shouldAttachBirthCertificateAndParentPassport() ? [
                                    ...(CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('birthCertificate') > -1 ? [{
                                        fileType: 'birthCertificate',
                                        filename: lodashGet(uploadedStageFiles, 'birthCertificate.filename'),
                                        fileContent: lodashGet(uploadedStageFiles, 'birthCertificate.base64')
                                    }] : []),
                                    ...(CONSTANTS.REQUIRED_IMAGES_FOR_MINOR.indexOf('parentPassport') > -1 ? [{
                                        fileType: 'parentPassport',
                                        filename: lodashGet(uploadedStageFiles, 'parentPassport.filename'),
                                        fileContent: lodashGet(uploadedStageFiles, 'parentPassport.base64')
                                    }] : [])
                                ] : []),
                                ...(areBusinessFieldsRelevant() ? [ {
                                    fileType: 'businessCard',
                                    filename: lodashGet(uploadedStageFiles, 'businessCard.filename'),
                                    fileContent: lodashGet(uploadedStageFiles, 'businessCard.base64')
                                } ] : []),
                            ]
                        }
                    ]
                });
                actions.onClose('success');
            }
        }
        catch (err) {
            // nothing to do
            setStage2CodeError(getLanguageValue('register-individual.stage2-subprocess-invalid-code-error-message'));
        }
        setLoaders({ ...loaders, confirmCode: false });
    }

    async function stage2ResendCode() {
        if (isLoaderActive()) {
            return;
        }
        setLoaders({ ...loaders, resendCode: true });
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({
                recaptchaToken,
                key: props.apiIndividualKey
            })
        };
        try {
            const url = getApiRoot() + 'resend-email/?type=individual&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let result = await (await fetch(url, options)).json();
            setLoaders({ ...loaders, resendCode: false });
            if (!lodashGet(result, 'data.success')) {
                if (result.err == 'too many retries') {
                    setStage2BottomError(getLanguageValue('register-individual.stage2-subprocess-resend-too-many-times-message'));
                }
                else {
                    setStage2CodeError(getLanguageValue('register-individual.stage2-subprocess-invalid-code-error-message'));
                }
                return;
            }
            // code ok
            setIsSentCodeAgain(true);
        }
        catch (err) {
            // nothing to do
            setStage2CodeError(getLanguageValue('register-individual.stage2-subprocess-invalid-code-error-message'));
            setLoaders({ ...loaders, resendCode: false });
            return;
        }
    }

    function stage2Cancel() {
        if (isLoaderActive()) {
            return;
        }
        actions.onClose('abort');
    }

    function isLoaderActive() {
        for (let loaderKey in loaders) {
            if (loaders[loaderKey]) {
                return true;
            }
        }
        return false;
    }

    function shouldAttachBirthCertificateAndParentPassport() {
        let duplicateData = JSON.parse(JSON.stringify(props.stage2Data));
        if (minorGoingWithParent && moment().diff(moment(duplicateData.birthDate, 'YYYY-MM-DD'), 'years') < 18) {
            return true;
        }
        return false;
    }

    function shouldAttachPassportBackside() {
        if (props.stage2Data.countryOfCitizenship.toLowerCase() == 'india') {
            return true;
        }
        return false;
    }

    function areBusinessFieldsRelevant() {
        return ['90days-multi'].indexOf(props.stage2Data.visaType) > -1;
    }
}

const GlobalStyle = createGlobalStyle`
  #modal-component .positioning-container {
    max-width: 600px;
    html.ltr & {
      max-width: 650px;
    }
  }
`;

const ModalContentStyle = styled.div`
  .actions-container {
    margin-top: 25px;
    text-align: center;
    > button {
      margin-left: 20px;
      margin-right: 20px;
      width: calc(100% - 50px);
      margin-top: 10px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 18px;
      }
    }
  }
  .error-message-container {
    margin-top: 10px;
    color: ${CSS_COLORS.FIELD_WARNING};
    text-align: center;
  }
  .field-component {
    margin-bottom: 20px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      width: calc(100% - 50px);
    }
  }
  .field-component input {
    text-align: center;
    font-size: 20px;
  }
  .loader-component {
    margin-right: 5px;
    margin-left: 5px;
  }
  .confirm-code-button, .resend-code-button, .cancel-button {
    white-space: nowrap;
    width: 140px;
  }
  .resend-code-button {
    width: calc(100% - 50px);
  }
  .error-container {
    margin-top: 10px;
    text-align: center;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .uploading-notification-container {
    font-weight: bold;
    color: blue;
  }
`;

const RegisterStage2SubProcess = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        individualKey: lodashGet(state, 'registerIndividualStagesData.key'),
        apiIndividualKey: lodashGet(state, 'apiData.individual.key'),
        groupKey: lodashGet(state, 'registerGroupStagesData.key'),
        stage2Data: lodashGet(state, 'registerIndividualStagesData.stage2Data'),
        registerIndividualStagesData: lodashGet(state, 'registerIndividualStagesData'),
        isIndividualLinkedToGroup: lodashGet(state, 'globalControls.isIndividualLinkedToGroup'),
        registerGroupStage2Data: lodashGet(state, 'registerGroupStagesData.stage2Data'),
    }),
{})(RegisterStage2SubProcessComponent);

export default RegisterStage2SubProcess;
